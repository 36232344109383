<template>
  <base-view
    title="Plantillas"
    description="Plantillas y layouts utilizados por el sistema."
    header-type="simplified"
    background="bg-gradient-black-to-blue"
  >
    <b-overlay :show="loading">
      <template #overlay>
        <div class="text-center">
          <loading message="Obteniendo archivo, por favor espere..."></loading>
        </div>
      </template>

      <h4 class="mb-0 mt-5">Plantillas usadas por el sistema.</h4>
      <hr class="mt-2 mb-4" />

      <b-row class="row">
        <b-col cols="12" lg="4" class="mb-4" v-for="item in layouts" :key="item.id">
          <!-- Layout para nóminas -->
          <div class="card lift lift-sm h-100 my-cursor">
            <div class="card-body">
              <h5 class="card-title mb-2" :class="item.textColor">
                <i class="mr-2" :data-feather="item.icon"></i>
                {{ item.title }}
              </h5>
              <p class="card-text mb-1">
                {{ item.desc }}
              </p>
            </div>
            <div class="card-footer">
              <div class="small text-muted" @click="onDownload(item.filename, item.ext)">{{ item.filename }}</div>
              <div class="small text-muted" @click="onDownload(item.example, item.ext)">{{ item.example }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </base-view>
</template>

<script>
import service from '@/api/service'

const DOWNLOAD_LAYOUT = 'api/download/plantillas'

export default {
  name: 'PlantillasPlaneacionPresupuestosView',

  data () {
    return {
      layouts: [
        {
          title: 'Layout para planeación capítulo 1000 recurso estatal',
          desc: 'Descarga el layout utilizado para la importación la plantilla de planeación.',
          filename: 'LAYOUT_PLANEACION CAPITULO_1000.xlsx',
          example: 'EJEMPLO_PLANEACION CAPITULO_1000.xlsx',
          ext: 'xlsx',
          textColor: 'text-yellow',
          icon: 'layers'
        },
        {
          title: 'Layout para presupuesto federal/ingresos propios',
          desc: 'Descarga el layout utilizado para la importación del presupuesto federal/ingresos propios.',
          filename: 'LAYOUT_PRESUPUESTO_FEDERAL.xlsx',
          example: 'EJEMPLO_PRESUPUESTO_FEDERAL.xlsx',
          ext: 'xlsx',
          textColor: 'text-purple',
          icon: 'layers'
        },
        {
          title: 'Layout para suficiencia presupuestal',
          desc: 'Descarga el layout utilizado para la importación de suficiencia presupuestal.',
          filename: 'LAYOUT_SUFICIENCIA_PRESUPUESTAL.xlsx',
          example: 'EJEMPLO_SUFICIENCIA_PRESUPUESTAL.xlsx',
          ext: 'xlsx',
          textColor: 'text-green',
          icon: 'layers'
        },
        {
          title: 'Layout para traspasos',
          desc: 'Descarga el layout utilizado para la importación de traspasos.',
          filename: 'LAYOUT_TRASPASOS.xlsx',
          example: 'EJEMPLO_TRASPASOS.xlsx',
          ext: 'xlsx',
          textColor: 'text-red',
          icon: 'layers'
        }
      ],

      loading: false
    }
  },

  methods: {
    async onDownload (filename, extension) {
      this.loading = true

      const filepath = 'planeacion_presupuestos'
      const { data } = await service({
        _method: 'GET',
        url: `${DOWNLOAD_LAYOUT}`,
        responseType: 'blob',
        params: { filepath, filename }
      })

      this.loading = false

      this.$onDownload(data, `${filename}.${extension}`)
    }
  }
}
</script>
